import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authUrl, clientId, rootUrl } from "../Hooks/EnviromentVariables";
import { refreshToken } from "../Auth/AuthProvider";
import { CustomAxios } from "../Hooks/CustomAxios";
import { Mutex } from "async-mutex";
const mutex = new Mutex();
const logoutUrl = process.env.REACT_APP_LOGOUT_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: "",
  credentials: "include",
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let response = null;
  response = await baseQuery(args, api, extraOptions);

  if (
    response?.error &&
    response?.error?.originalStatus === 403 &&
    response?.error?.data === "expired"
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      let refreshBool = await refreshToken(release);
      if (refreshBool) {
        response = await baseQuery(args, api, extraOptions);
      } else {
        try {
          const response = await CustomAxios({
            method: "delete",
            url: `${rootUrl}/auth/logout`,
          });
          if (response.status === 200) {
            window.location.href = `${authUrl}/logout?client_id=${clientId}&logout_uri=${logoutUrl}`;
          }
        } catch (error) {
          window.location.href = `${authUrl}/logout?client_id=${clientId}&logout_uri=${logoutUrl}`;
        }
      }
    } else {
      await mutex.waitForUnlock();
      response = await baseQuery(args, api, extraOptions);
    }
  }
  return response;
};
