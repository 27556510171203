import { colors } from "../../Styles/Colors";
import { CustomText } from "../../StyleComponents/Texts/CustomTexts";
import { fontWeight } from "../../Styles/Typography";
import backgroundIllustration from "../../Assets/Desktop.png";
import { Button, message, notification } from "antd";
import { HyperLink } from "../../StyleComponents/Links/CustomLinks";
import { useEffect } from "react";

const ComingSoonHome = () => {
  useEffect(() => {
    document.addEventListener("dragstart", (e) => e.preventDefault());
    return () => {
      document.removeEventListener("dragstart", (e) => e.preventDefault());
    };
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        backgroundImage: `url(${backgroundIllustration})`,
        backgroundPosition: "center",
        backgroundSize: "100vw 100vh",
      }}
    >
      <div
        className="d-flex flex-column"
        style={{
          paddingTop: "40vh",
          paddingLeft: "5vh",
          paddingRight: "5vh",
        }}
      >
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "15vh",
          }}
        >
          <h1
            style={{
              width: "100%",
              maxWidth: "1500px",
              height: "120px",
              fontStyle: "normal",
              fontWeight: 900,
              fontSize: "120px",
              lineHeight: "100%",
              letterSpacing: "-0.01em",
              textTransform: "uppercase",
              color: "#FFFFFF",
              textShadow: "0px -36px 50px #03F5FF, 0px 24px 35px #FF65C3",
              textAlign: "center", // ✅ This ensures text is centered inside the H1
            }}
          >
            COMING SOON
          </h1>
        </div>

        <div
          className="d-flex flex-row justify-content-center align-items-center"
          onClick={() => {
            notification.info({
              message: "Feature coming soon",
            });
          }}
        >
          <CustomText
            fontWeight={fontWeight?.bold}
            color={colors?.white}
            fontSize="32px"
            style={{
              textDecoration: "underline",
              textDecorationColor: "white",
              textDecorationThickness: "2px", // Adjust thickness for visibility
              textUnderlineOffset: "4px", // Adds spacing between text & underline
            }}
          >
            See Upcoming Events →
          </CustomText>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            backgroundColor: "transparent",
            padding: "20px",
            paddingTop: "10vh",
          }}
        >
          {/* Input Section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#2E3238",
              padding: "14px 20px",
              borderRadius: "12px",
              width: "400px",
            }}
          >
            <span style={{ color: "white", fontSize: "18px" }}>
              Subscribe to our Events Newsletter
            </span>
          </div>

          {/* Subscribe Button */}

          <Button
            className="d-flex flex-column justify-content-center "
            type="primary"
            style={{
              backgroundColor: "#FF65C3",
              borderRadius: "12px",
              padding: "14px 24px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "white",
              border: "none",
              height: "100%",
            }}
          >
            Subscribe
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonHome;
