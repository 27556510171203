import styled from "styled-components";
import { colors } from "../../Styles/Colors";

/**
 * @module LinkWaterBackground
 * @typedef {object} LinkWaterBackground
 * @property {string} text - The text to display as header text.
 * @returns {React.Element} A React element representing a header text component.
 * @property {string} [color] - The color of the subText text (optional).
 * @description This component renders a piece of header text with specified styling.
 * It can be used for displaying body/regular text content on a page.
 * The `CustomText` component accepts text to be displayed.
 * @example
 * // Import the CustomText component
 * import CustomText from './CustomText';
 *
 * // Use the CustomText in your component
 * function MyComponent() {
 *   return (
 *     <div>
 *       <CustomText text="This is a piece of header text." />
 *     </div>
 *   );
 * }
 *
 * export default MyComponent;
 */

export const LinkWaterBackground = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${colors?.white};
  padding: 35px 40px;
  overflow-y: auto;
`;

export const Background = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 35px 40px;
  overflow-y: auto;
`;

export const WhiteCard = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 4px rgba(240, 240, 240, 0.25),
    0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  position: ${(props) => props.position && props.position} !important;
`;

export const InkCard = styled.div`
  border: none;
  border-radius: 4px;
  background: ${colors.ink};
  display: flex;
  flex-direction: column;
`;
