import styled from "styled-components";
import { List } from "antd";
import { colors } from "../../Styles/Colors";

const CustomListStyle = styled(List)`
  .ant-spin-nested-loading {
    padding: 0px 16px !important;
  }

  .ant-list-header {
    background: ${colors?.diamond};
    box-shadow: none;
    border-radius: 8px;
    padding: 16px !important;
    border-block-end: 0px !important;
  }
  .ant-spin-nested-loading {
    padding: 0px !important;
  }
`;

/**
 * @module CustomList
 * @typedef {object} CustomList
 * @returns {React.Element} A React element representing a header text component.
 * @description This component renders a custom list that is a styled Ant Design list and for more information
 * refer to the ant design docs
 * @example
 * // Import the CustomList component
 * import CustomList from './CustomList';
 *
 * // Use the CustomList in your component
 * function MyComponent() {
 *   return (
  <CustomList
          className=" mt-5 "
          loading={camerasIsFetching}
          dataSource={filteredCameras}
          pagination={{ pageSize: "4" }}
          header={
            <div className="d-flex flex-row align-items-center justify-content-center w-100">
              <div className="d-flex flex-column justify-content-center align-items-start col-3">
                <SemiBoldText>Camera Name</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>Location</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>View Camera Details</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>Camera Access</SemiBoldText>
              </div>
            </div>
          }
          renderItem={(item) => (
            <div className="mt-4">
              <div className="d-flex flex-row  align-items-center justify-content-center w-100">
                <div className="d-flex flex-column justify-content-center align-items-start col-3">
                  <NormalText
                    color={
                      item?.alertType === "GUN_DETECTED" && colors.punchRed
                    }
                  >
                    {item?.name}
                  </NormalText>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <NormalText>{item?.locationName}</NormalText>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <HyperLink
                    onClick={() => {
                      setCurrentCamera(item);
                      setCameraLandingStep("singleCamera");
                    }}
                    color={colors.dodgerBlue}
                  >
                    View
                  </HyperLink>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <HyperLink
                    onClick={() => {
                      setCurrentCamera(item);
                      setCameraLandingStep("cameraAccess");
                    }}
                    color={colors.dodgerBlue}
                  >
                    View / Edit Camera Access
                  </HyperLink>
                </div>
              </div>
            </div>
          )}
        />
 *   );
 * }
 *
 * export default MyComponent;
 */

export const CustomList = ({
  className,
  loading,
  dataSource,
  pagination,
  header,
  renderItem,
}) => {
  return (
    <CustomListStyle
      header={header}
      loading={loading}
      className={className}
      renderItem={renderItem}
      pagination={pagination}
      dataSource={dataSource}
    />
  );
};

export default CustomList;
