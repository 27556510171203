import { List } from "antd";
import {
  Background,
  WhiteCard,
} from "../../StyleComponents/Background/CustomBackgrounds";
import { HyperLink } from "../../StyleComponents/Links/CustomLinks";
import CustomList from "../../StyleComponents/Lists/CustomList";
import {
  NormalText,
  SemiBoldText,
} from "../../StyleComponents/Texts/CustomTexts";
import { CustomButton } from "../../StyleComponents/Buttons/CustomButton";
import { useEffect, useState } from "react";

const ListFiles = ({
  jobID,
  currStep,
  setCurrStep,
  files,
  setFiles,
  setSelectedFile,
  modelsUsed,
  setModelsUsed,
  setSelectedFileIndex,
}) => {
  const currentUrl = window.location.href;
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState(null);
  const [shouldPoll, setShouldPoll] = useState(true);
  const intervalTime = 2000;

  useEffect(() => {
    if (!shouldPoll) return;
    let isMounted = true; // Prevents setting state on unmounted components
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      setLoading(true);

      try {
        //local testing
        // const response = await fetch(`http://127.0.0.1:8000/jobs/${jobID}`, {

        //local testing

        let response;
        if (currentUrl?.includes("localhost")) {
          // local host testing

          //0da0122f-7ca1-443b-ada8-185e77e20d34

          response = await fetch(`http://127.0.0.1:8000/jobs/${jobID}`, {
            signal,
          });
        } else {
          // ec2

          response = await fetch(
            `https://mfuws72uzl.execute-api.us-east-1.amazonaws.com/jobs/${jobID}`,
            {
              signal,
            }
          );
        }

        if (!response.ok) throw new Error("Network response was not ok");

        const result = await response.json();

        if (isMounted) {
          // set modelUsed state
          if (result?.models) {
            setModelsUsed([...result?.models]);
          }
          // set Files state
          if (result?.files) {
            let newFilesData = result?.files;

            // Stop polling if all jobs are completed
            let tempBool = false;

            for (let i = 0; i < newFilesData.length; i++) {
              if (
                newFilesData[i]?.status !== "FINISHED" &&
                newFilesData[i]?.status !== "ERROR"
              ) {
                tempBool = true;
                break;
              }
            }

            if (!tempBool) {
              setShouldPoll(false);
            }

            setFiles([...newFilesData]);
            setLoading(false);
          }
        }
      } catch (err) {
        if (isMounted) setError(err.message);
        setLoading(false);
      }

      return () => controller.abort(); // Cancel request on component unmount
    };

    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, intervalTime);

    return () => {
      isMounted = false;
      controller.abort();
      clearInterval(interval);
    };
  }, [intervalTime, shouldPoll]); // Re-run effect if interval time changes

  const goToHomeOnListFiles = () => {
    setFiles([]);
    if (currStep === "listFiles") {
      setCurrStep("uploadFiles");
    } else {
      setCurrStep("jobsHistoryList");
    }
  };

  return (
    <Background>
      <WhiteCard
        style={{
          minHeight: "82vh",
          paddingBottom: "80px", // Prevents overlap with fixed button
          position: "relative",
        }}
        className="row"
      >
        <CustomList
          className=""
          loading={loading}
          dataSource={files}
          border
          header={
            <div className="d-flex flex-row align-items-center justify-content-center w-100">
              <div className="d-flex flex-column justify-content-center align-items-start col-3">
                <SemiBoldText>File Name</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>Status</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>Total Models Used</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>View </SemiBoldText>
              </div>
            </div>
          }
          renderItem={(item, index) => (
            <List.Item
              style={{ padding: "15px" }}
              className=" d-flex flex-row w-100"
            >
              <div className="d-flex flex-row  align-items-center justify-content-center w-100">
                <div className="d-flex flex-column justify-content-center align-items-start col-3">
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <SemiBoldText>{item?.file_name}</SemiBoldText>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <NormalText>{item?.status}</NormalText>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <NormalText>{item?.models?.length}</NormalText>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <HyperLink
                    onClick={() => {
                      setSelectedFile(item);
                      setSelectedFileIndex(index);
                      if (currStep === "listFiles") {
                        setCurrStep("modelsUsed");
                      } else {
                        setCurrStep("jobsHistoryModelsUsed");
                      }
                    }}
                  >
                    View
                  </HyperLink>
                </div>
              </div>
            </List.Item>
          )}
        />

        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomButton
            width="250px"
            customtype="primary"
            onClick={() => goToHomeOnListFiles()}
          >
            Go to {currStep === "listFiles" ? "Home" : "Job History"}
          </CustomButton>
        </div>
      </WhiteCard>
    </Background>
  );
};

export default ListFiles;
