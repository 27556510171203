import styled from "styled-components";
import { Modal } from "antd";
import { colors } from "../../Styles/Colors";

const CustomModalStyle = styled(Modal)`
  border-radius: 8px;
  background: ${colors?.white};

  .ant-modal-content {
    background: ${colors?.white};
    box-shadow: none;
    padding: 20px 24px 0px 24px;
  }
  .ant-modal-title {
    background: ${colors?.white};
    box-shadow: none;
  }
`;

/**
 * @module CustomModal
 * @typedef {object} CustomModal
 * @property {string} width - The width of the modal needed.
 * @returns {React.Element} A React element representing a header text component.
 * @description This component renders a custom modal that is a styled Ant Design modal and for more information
 * refer to the ant design docs
 * @example
 * // Import the CustomModal component
 * import CustomModal from './CustomModal';
 *
 * // Use the CustomModal in your component
 * function MyComponent() {
 *   return (
 *        <CustomModal
      width={"850px"}
      footer={null}
      open={true}
      onCancel={() => {
        toggleAddFacilityPersonnelModal();
      }}
      title={<TitleText>ADD ASSET PERSONNEL</TitleText>}
    >
    put your content here
    </CustomModal>
 *   );
 * }
 *
 * export default MyComponent;
 */

export const CustomModal = ({
  className,
  width,
  loading,
  onCancel,
  title,
  content,
  children,
}) => {
  return (
    <CustomModalStyle
      open={true}
      footer={null}
      width={width}
      onCancel={onCancel}
      className={className}
      loading={loading}
      title={title}
      maskClosable={false}
    >
      {children}
    </CustomModalStyle>
  );
};

export default CustomModal;
