import { List } from "antd";
import {
  Background,
  WhiteCard,
} from "../../StyleComponents/Background/CustomBackgrounds";
import { HyperLink } from "../../StyleComponents/Links/CustomLinks";
import CustomList from "../../StyleComponents/Lists/CustomList";
import {
  NormalText,
  SemiBoldText,
} from "../../StyleComponents/Texts/CustomTexts";
import { CustomButton } from "../../StyleComponents/Buttons/CustomButton";
import { useEffect, useState } from "react";

const ModelsUsed = ({
  modelsUsed,
  currStep,
  setCurrStep,
  files,
  selectedFile,
  setSelectedFileIndex,
  setSelectedModelIndex,
  setDetections,
}) => {
  const goToHomeOnModelsUsed = () => {
    if (currStep === "modelsUsed") {
      setCurrStep("uploadFiles");
    } else {
      setCurrStep("jobsHistoryList");
    }
  };

  const renderTotalDetections = (index) => {
    return selectedFile?.models[index]?.detections?.length;
  };

  return (
    <Background>
      <WhiteCard
        style={{
          minHeight: "82vh",
          paddingBottom: "80px", // Prevents overlap with fixed button
          position: "relative",
        }}
        className="row"
      >
        <CustomList
          className=""
          dataSource={modelsUsed}
          border
          header={
            <div className="d-flex flex-row align-items-center justify-content-center w-100">
              <div className="d-flex flex-column justify-content-center align-items-start col-3">
                <SemiBoldText>Model</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>Total Detections</SemiBoldText>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>View </SemiBoldText>
              </div>
            </div>
          }
          renderItem={(item, index) => (
            <List.Item
              style={{ padding: "15px" }}
              className=" d-flex flex-row w-100"
            >
              <div className="d-flex flex-row  align-items-center justify-content-center w-100">
                <div className="d-flex flex-column justify-content-center align-items-start col-3">
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <SemiBoldText>{item}</SemiBoldText>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <NormalText>{renderTotalDetections(index)}</NormalText>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <HyperLink
                    onClick={() => {
                      setSelectedModelIndex(index);
                      setDetections(selectedFile?.models[index]?.detections);
                      if (currStep === "modelsUsed") {
                        setCurrStep("viewFile");
                      } else {
                        setCurrStep("jobsHistoryViewFile");
                      }
                    }}
                  >
                    View
                  </HyperLink>
                </div>
              </div>
            </List.Item>
          )}
        />

        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <CustomButton
            width="250px"
            customtype="primary"
            onClick={() => {
              if (currStep === "modelsUsed") {
                setCurrStep("listFiles");
              } else {
                setCurrStep("jobsHistoryListFiles");
              }
            }}
          >
            Go to Files
          </CustomButton>
          <CustomButton
            width="250px"
            customtype="primary"
            onClick={() => goToHomeOnModelsUsed()}
          >
            Go to {currStep === "modelsUsed" ? "Home" : "Job History"}
          </CustomButton>
        </div>
      </WhiteCard>
    </Background>
  );
};

export default ModelsUsed;
