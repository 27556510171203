import { Card, Col, Divider, Image, Row } from "antd";
import {
  Background,
  WhiteCard,
} from "../../StyleComponents/Background/CustomBackgrounds";
import { HyperLink } from "../../StyleComponents/Links/CustomLinks";
import { CustomText } from "../../StyleComponents/Texts/CustomTexts";
import { CustomButton } from "../../StyleComponents/Buttons/CustomButton";
import { colors } from "../../Styles/Colors";
import { useState } from "react";
import ViewFileModal from "./ViewFileModal";

const ViewFile = ({
  currStep,
  setCurrStep,
  setFiles,
  selectedFile,
  setSelectedFile,
  detections,
}) => {
  const [showViewFileModal, setShowViewFileModal] = useState(false);
  const [startTime, setStartTime] = useState(0);

  const handleModalClose = () => {
    setShowViewFileModal(!showViewFileModal);
  };

  const goToHomeOnViewFile = () => {
    setFiles([]);
    setSelectedFile(null);

    if (currStep === "viewFile") {
      setCurrStep("uploadFiles");
    } else {
      setCurrStep("jobsHistoryList");
    }
  };

  return (
    <Background>
      <WhiteCard
        style={{
          minHeight: "82vh",
          paddingBottom: "80px", // Prevents overlap with fixed button
          position: "relative",
        }}
        className="row"
      >
        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
          {/* Left Column - Detections */}
          <div>
            <CustomText
              fontWeight="600"
              fontSize="20px"
              color={colors.ink}
              style={{ lineHeight: "1.4" }}
            >
              Detections
            </CustomText>
            <CustomText
              className="ml-5"
              fontWeight="600"
              fontSize="20px"
              color={colors.ink}
              style={{ lineHeight: "1.4" }}
            >
              ({detections?.length})
            </CustomText>
          </div>

          {/* Right Column - File Details */}
          <div className="d-flex align-items-center gap-3 flex-wrap">
            <CustomText
              fontWeight="600"
              fontSize="20px"
              color={colors.ink}
              style={{ lineHeight: "1.4" }}
            >
              File Name:
            </CustomText>
            <CustomText
              fontWeight="500"
              fontSize="20px"
              color={colors.ink}
              style={{ lineHeight: "1.4" }}
            >
              {selectedFile?.file_name}
            </CustomText>
            <HyperLink
              style={{ lineHeight: "1.4" }}
              onClick={() => {
                setStartTime(0);
                setShowViewFileModal(true);
              }}
            >
              View File
            </HyperLink>
          </div>
        </div>

        <Divider style={{ marginTop: "20px" }} />

        <Row gutter={[16, 16]}>
          {detections.map((image, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
              <Card
                hoverable
                cover={
                  <Image
                    src={image.url}
                    alt={`Gallery ${index}`}
                    style={{ height: "200px", objectFit: "cover" }}
                  />
                }
              >
                <HyperLink
                  className="d-flex flex-row justify-content-center"
                  fontWeight="600"
                  fontSize="20px"
                  color={colors.ink}
                  onClick={() => {
                    setStartTime(image?.time_sec);
                    setShowViewFileModal(true);
                  }}
                >
                  {image?.time_str}
                </HyperLink>
              </Card>
            </Col>
          ))}
        </Row>

        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <CustomButton
            width="250px"
            customtype="primary"
            onClick={() => {
              if (currStep === "viewFile") {
                setCurrStep("modelsUsed");
              } else {
                setCurrStep("jobsHistoryModelsUsed");
              }
            }}
          >
            Go to Models List
          </CustomButton>
          <CustomButton
            width="250px"
            customtype="primary"
            onClick={() => {
              if (currStep === "viewFile") {
                setCurrStep("listFiles");
              } else {
                setCurrStep("jobsHistoryListFiles");
              }
            }}
          >
            Go to Files
          </CustomButton>
          <CustomButton
            width="250px"
            customtype="primary"
            onClick={() => goToHomeOnViewFile()}
          >
            Go to {currStep === "viewFile" ? "Home" : "Job History"}
          </CustomButton>
        </div>
      </WhiteCard>

      {showViewFileModal && (
        <ViewFileModal
          handleModalClose={handleModalClose}
          startTime={startTime}
          selectedFile={selectedFile}
        />
      )}
    </Background>
  );
};

export default ViewFile;
