import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./ReAuthSlice";

//It is recommended to have the tag types in rtk query documentation
const tagTypes = [
  "documents",
  "users",
  "facilities",
  "documentTypes",
  "variableGroups",
  "facilityUsers",
  "comments",
  "documentLifecycleList",
  "publisherLogList",
  "versions",
  "documentParticipants",
  "sections",
  "alerts",
  "alertDTOList",
];

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  tagTypes: tagTypes,
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => {
    return {
      get: builder.query({
        query: (data = {}) => {
          let query = data?.query || "";
          if (data?.queryFilter) {
            // turns queryFilter object into a query string
            query = `${query}${data?.page ? `?page=${data?.page}` : "?page=1"}${
              data?.size ? `&size=${data?.size}` : "&size=100"
            }&${new URLSearchParams(data?.queryFilter).toString()}`;
          }
          return query;
        },

        providesTags: (result, error, data) => {
          return data.tags;
        },

        transformResponse: (response, meta, arg) => {
          let transformedResponse = {};
          if (arg?.type === "single") {
            transformedResponse = response;
          } else {
            transformedResponse = {
              data: response?._embedded?.[arg?.tags?.[0]],
              page: response?.page,
            };
          }
          return transformedResponse;
        },

        transformErrorResponse: (response, meta, arg) => {
          return response;
        },
      }),
      updateOrg: builder.mutation({
        query: (data) => {
          return {
            url: data.query,
            method: "PATCH",
            body: data.data,
            headers: { "X-CSRFToken": localStorage.getItem("csrf") },
          };
        },
        transformResponse: (response, meta, arg) => {
          return response;
        },
        transformErrorResponse: (response, meta, arg) => {
          return response.status;
        },
      }),
      postOrg: builder.mutation({
        query: (data) => {
          return {
            url: data.query,
            method: "POST",
            body: data.data,
            headers: { "X-CSRFToken": localStorage.getItem("csrf") },
          };
        },
        transformResponse: (response, meta, arg) => {
          return response;
        },
        transformErrorResponse: (response, meta, arg) => {
          return response.status;
        },
      }),
    };
  },
});

export const { useGetQuery } = apiSlice;
