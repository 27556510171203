import CustomModal from "../../StyleComponents/Modals/CustomModal";
import { TitleText } from "../../StyleComponents/Texts/CustomTexts";

const ViewFileModal = ({ handleModalClose, startTime, selectedFile }) => {
  const videoBaseUrl = selectedFile?.url;
  const videoUrl = `${videoBaseUrl}#t=${startTime}`;

  return (
    <CustomModal
      open={true}
      width={1000}
      footer={null}
      onCancel={() => {
        handleModalClose();
      }}
      title={
        <TitleText className="d-flex flex-row justify-content-center">
          View File
        </TitleText>
      }
    >
      <div
        className="d-flex justify-content-center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "900px", // Restrict max width
            position: "relative",
            borderRadius: "12px",
            overflow: "hidden",
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
            background: "#000",
          }}
        >
          <video
            width="100%"
            height="auto"
            controls
            style={{
              borderRadius: "12px",
              display: "block",
              aspectRatio: "16/9", // Ensures responsive aspect ratio
            }}
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </CustomModal>
  );
};

export default ViewFileModal;
