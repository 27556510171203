export const callBackUrl = process.env.REACT_APP_CALLBACK_URL;
export const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
export const rootUrl = process.env.REACT_APP_ROOT_URL;
export const clientId = process.env.REACT_APP_CLIENT_ID;
export const authUrl = process.env.REACT_APP_AUTH_URL;
export const webSocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
export const cloudFrontId = process.env.REACT_APP_CLOUDFRONT_ID;
export const hlsUrl = process.env.REACT_APP_HLS_URL;
export const awsAccessKey = process.env.REACT_APP_AWS_ACCESS_KEY;
export const awsSecretKey = process.env.REACT_APP_AWS_SECRET_KEY;
