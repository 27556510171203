import React, { createContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateAuth } from "../Redux/AuthSlice";
import { setUserData } from "../Redux/UserSlice";
import { authUrl, clientId, rootUrl } from "../Hooks/EnviromentVariables";
import { CustomAxios } from "../Hooks/CustomAxios";
import { Mutex } from "async-mutex";
import { notification } from "antd";
const mutex = new Mutex();
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const callBackUrl = process.env.REACT_APP_CALLBACK_URL;
  const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
  // check authenticated end point
  const checkAuth = async () => {
    try {
      //authenticatuion api
      const response = await axios.get(`${rootUrl}/auth/isauthenticated`, {
        withCredentials: true,
      });
      //if authenticated
      if (response.status === 200) {
        dispatch(updateAuth(true));
        dispatch(setUserData(response.data));

        let userProps = { userData: response.data, isAuthenticated: true };
        return userProps;
      }
    } catch (error) {
      // if authenticated failed check if refresh token is still valid.
      let refreshBool = false;
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data === "expired"
      ) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          refreshBool = await refreshToken(release);
        }
      }

      if (refreshBool) {
        const data = await checkAuth();
        if (data && typeof data?.isAuthenticated !== "undefined") {
          return data;
        } else {
          return {
            user: null,
            isAuthenticated: false,
          };
        }
      } else {
        return {
          user: null,
          isAuthenticated: false,
        };
      }
    }
  };

  const login = async (setLoading) => {
    setLoading && setLoading(true);
    window.location.href = `${authUrl}/oauth2/authorize?client_id=${clientId}&response_type=code&scope=email+openid+profile&redirect_uri=${callBackUrl}`;
  };

  const logout = async () => {
    const response = await axios({
      method: "delete",
      url: `${rootUrl}/auth/logout`,
      withCredentials: true,
    });
    if (response.status === 200) {
      window.location.href = `${authUrl}/logout?client_id=${clientId}&logout_uri=${logoutUrl}`;
    }
  };

  const apiCallWrapper = async ({
    apiFunction,
    setLoading,
    successMessage,
    errorMessage,
  }) => {
    setLoading && setLoading(true);
    try {
      await apiFunction();
      setLoading && setLoading(false);
      successMessage && notification.success({ message: successMessage });
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data === "expired"
      ) {
        let refreshBool;
        setLoading && setLoading(true);
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          refreshBool = await refreshToken(release);
        }
        if (refreshBool) {
          await apiFunction();
          setLoading && setLoading(false);
          successMessage && notification.success({ message: successMessage });
        } else {
          localStorage.removeItem("csrf");
          dispatch(updateAuth(false));
          navigate("/login");
        }
      } else {
        (error.message || errorMessage) &&
          notification.error({
            message: error.message || errorMessage,
          });
        setLoading && setLoading(false);
      }
    }
  };

  return (
    <AuthContext.Provider value={{ checkAuth, login, logout, apiCallWrapper }}>
      {children}
    </AuthContext.Provider>
  );
};

export const refreshToken = async (release) => {
  try {
    let response = await axios.post(
      `${rootUrl}/auth/refresh-token`,
      {},
      {
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      if (response?.data?.data?.csrfToken) {
        localStorage.setItem("csrf", response?.data?.data?.csrfToken);
        CustomAxios.defaults.headers["X-CSRFToken"] =
          response?.data?.data?.csrfToken;
      }
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  } finally {
    release();
  }
};
