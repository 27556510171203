import {
  Background,
  WhiteCard,
} from "../../StyleComponents/Background/CustomBackgrounds";
import { CustomText } from "../../StyleComponents/Texts/CustomTexts";
import { CustomButton } from "../../StyleComponents/Buttons/CustomButton";
import { colors } from "../../Styles/Colors";
import { v4 as uuidv4 } from "uuid";

const GetStartedPage = ({ setCurrStep, files, setFiles, setJobID }) => {
  const getStarted = () => {
    const uniqueId = uuidv4();
    setJobID(uniqueId);
    setCurrStep("uploadFiles");
  };

  return (
    <Background
      style={{
        minHeight: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(145deg, ${colors.lightBlue}, ${colors.lightGray})`,
      }}
    >
      <WhiteCard
        style={{
          width: "100%",
          maxWidth: "800px",
          padding: "30px",
          borderRadius: "10px",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          minHeight: "60vh",
        }}
        className="row"
      >
        <CustomText
          className="d-flex flex-column justify-content-center"
          fontWeight="700"
          fontSize="28px"
          color={colors.ink}
          style={{ marginBottom: "20px", lineHeight: "1.4" }}
        >
          Experience the Future of Security with State-of-the-Art AI
        </CustomText>
        <CustomText
          className="d-flex flex-column justify-content-center"
          fontWeight="600"
          fontSize="16px"
          color={colors.ink}
          style={{
            marginBottom: "15px",
            lineHeight: "1.7",
            opacity: "0.8",
          }}
        >
          This platform showcases our cutting-edge Visual Attention-Based AI
          Model for real-time firearm detection. Built on a state-of-the-art
          deep learning architecture, our system leverages Vision Transformers
          (ViTs) and attention mechanisms to dynamically focus on regions of
          interest, improving both precision and computational efficiency.
        </CustomText>
        <CustomText
          className="d-flex flex-column justify-content-center"
          fontWeight="600"
          fontSize="16px"
          color={colors.ink}
          style={{
            marginBottom: "30px",
            lineHeight: "1.7",
            opacity: "0.8",
          }}
        >
          By integrating self-attention mechanisms with spatiotemporal feature
          extraction, our model ensures high detection accuracy in complex
          environments, even under occlusions and varying lighting conditions.
          The system processes images and video streams in real-time, leveraging
          hierarchical attention layers to distinguish firearms from other
          objects with minimal false positives.
        </CustomText>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <CustomButton
            className="mt-5"
            width="250px"
            customtype="primary"
            onClick={() => getStarted()}
          >
            Get Started
          </CustomButton>
        </div>
      </WhiteCard>
    </Background>
  );
};

export default GetStartedPage;

// import { List } from "antd";
// import {
//   Background,
//   WhiteCard,
// } from "../../StyleComponents/Background/CustomBackgrounds";
// import { HyperLink } from "../../StyleComponents/Links/CustomLinks";
// import CustomList from "../../StyleComponents/Lists/CustomList";
// import {
//   CustomText,
//   NormalText,
//   SemiBoldText,
// } from "../../StyleComponents/Texts/CustomTexts";
// import { CustomButton } from "../../StyleComponents/Buttons/CustomButton";
// import { colors } from "../../Styles/Colors";
// import { v4 as uuidv4 } from "uuid";

// const GetStartedPage = ({ setCurrStep, files, setFiles, setJobID }) => {
//   const getStarted = () => {
//     const uniqueId = uuidv4();
//     setJobID(uniqueId);
//     setCurrStep("uploadFiles");
//   };

//   return (
//     <Background>
//       {/* <Back
//         backFunction={() => setCurrStep("uploadFiles")}
//         text={"BACK"}
//       ></Back> */}

//       <WhiteCard
//         style={{
//           minHeight: "82vh",
//           paddingBottom: "80px", // Prevents overlap with fixed button
//           position: "relative",
//         }}
//         className="row"
//       >
//         <CustomText
//           className="d-flex flex-row justify-content-center"
//           fontWeight="700"
//           fontSize="20px"
//           color={colors.ink}
//         >
//           Experience the Future of Security with State-of-the-Art AI
//         </CustomText>
//         <CustomText
//           className="d-flex flex-row justify-content-center align-items-center mt-5"
//           fontWeight="600"
//           fontSize="15px"
//           color={colors.ink}
//         >
//           This platform showcases our cutting-edge Visual Attention-Based AI
//           Model for real-time firearm detection. Built on a state-of-the-art
//           deep learning architecture, our system leverages Vision Transformers
//           (ViTs) and attention mechanisms to dynamically focus on regions of
//           interest, improving both precision and computational efficiency.
//         </CustomText>
//         <CustomText
//           className="d-flex flex-row justify-content-center align-items-center mt-3"
//           fontWeight="600"
//           fontSize="15px"
//           color={colors.ink}
//         >
//           By integrating self-attention mechanisms with spatiotemporal feature
//           extraction, our model ensures high detection accuracy in complex
//           environments, even under occlusions and varying lighting conditions.
//           The system processes images and video streams in real-time, leveraging
//           hierarchical attention layers to distinguish firearms from other
//           objects with minimal false positives.
//         </CustomText>

//         <div className="d-flex flex-row justify-content-center">
//           <CustomButton
//             className="mt-5"
//             width="250px"
//             customtype="primary"
//             onClick={() => getStarted()}
//           >
//             Get Started
//           </CustomButton>
//         </div>
//       </WhiteCard>
//     </Background>
//   );
// };

// export default GetStartedPage;
