/*
Standard colors to be used across project match the names with figma design names.
*/
export const colors = {
  astronautBlue: "#3A6082",
  midnightGrey: "#152222",
  dodgerBlue: "#1677FF",
  silverGrey: "#c2c2c2",
  elmGreen: "#167c88",
  wildSand: "#f5f5f5",
  punchRed: "#da3833",
  white: "#ffffff",
  yellowOrange: "#ffab15",
  athensGrey: "#EFEEF2",
  linkWater: "#F8FAFD",
  disabledGray: "#8E979B",
  spaceNight: "#2E2E2E",
  orbit: "#062744",
  galaxy: "#AD37E5",
  purpleGalaxy: "#AD37E5",
  athens: "#E8E8E8",
  cloud: "#7A7A7A",
  kylian: "#080D12",
  diamond: "#E5F3FF",
  leaf: "#0FA862",
  goodr: "#0D8C52",
  darkGray: "#999999",
  ink: "#0E1720",
  gadget: "#1F1F1F",
  redrocher: "#FDF2F2",
  eastwood: "#FFF0D6",
};

export const colorsSpecial = [
  { name: "Red", color: "#E02B18" },
  { name: "Blue", color: "#2B18E0" },
  { name: "Green", color: "#119D2E" },
  { name: "White", color: "#f5f5f5" },
  { name: "Purple", color: "#8F18E0" },
  { name: "Yellow", color: "#EDC908" },
  { name: "Orange", color: "#E08F18" },
  { name: "Black", color: "#1C0703" },
  { name: "Gray", color: "#8F8C8B" },
];
