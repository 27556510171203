import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    setUserData: (state, action) => {
      Object.keys(action.payload).forEach((val) => {
        state[val] = action.payload[val];
      });
    },
  },
});

export const { setUserData } = UserSlice.actions;
export default UserSlice.reducer;
