/*
Standard fonts to be used across project
*/

export const fonts = {
  primary: "Poppins",
  secondary: "Work Sans",
};

/*
Standard font sizes to be used across project
*/
export const fontSize = {
  header: "24px",
  title: "18px",
  body: "16px",
  subtext: "14px",
  microText: "12px",
};

/*
Standard font weight  to be used across project
*/
export const fontWeight = {
  extra: "800",
  bold: "700",
  mediumBold: "500",
  regular: "400",
  light: "300",
};
