import styled from "styled-components";
import leftArrow from "../../Assets/Icons/DodgerBlue/arrowLeft.png";
import rightArrow from "../../Assets/Icons/DodgerBlue/arrowRight.png";
import { colors } from "../../Styles/Colors";
import { BoldText } from "../Texts/CustomTexts";
import { fontSize, fontWeight, fonts } from "../../Styles/Typography";

/**
 * @module Back
 * @typedef {object} Back
 * @param {string} text - The text to display as Back text.
 * @param {function} backFunction -this is the function you pass to change the page to the previous one
 * @returns {React.Element} A React element representing a Back text component.
 * @description This component renders a piece of Back text with specified styling.
 * The `Back` component accepts text to be displayed. This component is
 * mostly used for the purpose of sending a user to a previous page
 * @example
 * // Import the Back component
 * import Back from './Back';
 *
 * // Use the Back in your component
 * function MyComponent() {
 *   return (
 *        <Back
          text="BACK"
          backFunction={() => {
            setFacilitiesStep("facilities");
          }}
        />
 *   );
 * }
 *
 * export default MyComponent;
 */

export const Back = ({ text, backFunction, className }) => {
  return (
    <div
      className={className}
      style={{ cursor: "pointer", height: "40px" }}
      onClick={() => backFunction && backFunction()}
    >
      <img
        alt="leftarrowpic"
        src={leftArrow}
        style={{
          marginBottom: "2px",
          height: "23px",
          width: "23px",
          cursor: "pointer",
          marginRight: "10px",
        }}
      />
      <BoldText color={colors.dodgerBlue}>{text}</BoldText>
    </div>
  );
};

/**
 * @module Next
 * @typedef {object} Next
 * @param {string} text - The text to display as Next text.
 * @param {function} NextFunction -this is the function you pass to change the page to the previous one
 * @returns {React.Element} A React element representing a Next text component.
 * @description This component renders a piece of Next text with specified styling.
 * The `Next` component accepts text to be displayed. This component is
 * mostly used for the purpose of sending a user to a previous page
 * @example
 * // Import the Next component
 * import Next from './Next';
 *
 * // Use the Next in your component
 * function MyComponent() {
 *   return (
 *        <Next
          text="Next"
          nextFunction={() => {
            setFacilitiesStep("facilities");
          }}
        />
 *   );
 * }
 *
 * export default MyComponent;
 */

export const Next = ({ text, nextFunction, className }) => {
  return (
    <div
      className={className}
      style={{ cursor: "pointer", height: "32px" }}
      onClick={() => nextFunction && nextFunction()}
    >
      <BoldText color={colors.dodgerBlue}>{text}</BoldText>{" "}
      <img
        alt="leftarrowpic"
        src={rightArrow}
        style={{
          marginBottom: "2px",
          height: "23px",
          width: "23px",
          cursor: "pointer",
          marginLeft: "10px",
        }}
      />
    </div>
  );
};

const StyledHyperLink = styled.span`
  color: ${(props) =>
    props.disabled === true ? colors.silverGrey : colors.dodgerBlue} !important;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;
  font-family: ${fonts.primary};
  font-size: ${fontSize.body};
  font-weight: ${fontWeight.bold};
  text-decoration: underline;
  text-decoration-thickness: 2px;
`;

/**
 * @module HyperLink
 * @typedef {object} HyperLink
 * @property {string} text - The text to display as header text.
 * @returns {React.Element} A React element representing a header text component.
 * @property {string} [color] - The color of the subText text (optional).
 * @description This component renders a piece of header text with specified styling.
 * It can be used for displaying body/regular text content on a page.
 * The `HyperLink` component accepts text to be displayed.
 * @example
 * // Import the HyperLink component
 * import HyperLink from './HyperLink';
 *
 * // Use the HyperLink in your component
 * function MyComponent() {
 *   return (
 *     <div>
 *       <HyperLink text="This is a piece of header text." />
 *     </div>
 *   );
 * }
 *
 * export default MyComponent;
 */

export const HyperLink = (props) => {
  return (
    <StyledHyperLink
      loading={props?.loading}
      className={props?.className}
      disabled={props?.disabled}
      onClick={!props?.disabled && !props?.loading ? props?.onClick : () => {}}
    >
      {props?.children}
    </StyledHyperLink>
  );
};
