import { useState } from "react";
import UploadFiles from "./UploadFiles";
import ListFiles from "./ListFiles";
import GetStartedPage from "./GetStartedPage";
import ViewFile from "./ViewFile";
import JobsHistoryList from "./JobsHistoryList";
import ModelsUsed from "./ModelsUsed";

const Landing = () => {
  const [jobID, setJobID] = useState(null);
  const [currStep, setCurrStep] = useState("getStartedPage");
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [modelsUsed, setModelsUsed] = useState([]);
  const [selectedModelIndex, setSelectedModelIndex] = useState(null);
  const [detections, setDetections] = useState(selectedFile?.detections);

  return (
    <>
      {currStep === "getStartedPage" && (
        <GetStartedPage
          setCurrStep={setCurrStep}
          files={files}
          setFiles={setFiles}
          setJobID={setJobID}
        />
      )}
      {currStep === "uploadFiles" && (
        <UploadFiles
          jobID={jobID}
          setCurrStep={setCurrStep}
          files={files}
          setFiles={setFiles}
        />
      )}
      {(currStep === "listFiles" || currStep === "jobsHistoryListFiles") && (
        <ListFiles
          jobID={jobID}
          currStep={currStep}
          setCurrStep={setCurrStep}
          files={files}
          setFiles={setFiles}
          setSelectedFile={setSelectedFile}
          setSelectedFileIndex={setSelectedFileIndex}
          modelsUsed={modelsUsed}
          setModelsUsed={setModelsUsed}
        />
      )}
      {(currStep === "modelsUsed" || currStep === "jobsHistoryModelsUsed") && (
        <ModelsUsed
          currStep={currStep}
          setCurrStep={setCurrStep}
          files={files}
          setFiles={setFiles}
          setSelectedFile={setSelectedFile}
          modelsUsed={modelsUsed}
          setModelsUsed={setModelsUsed}
          selectedFile={selectedFile}
          selectedFileIndex={selectedFileIndex}
          setSelectedModelIndex={setSelectedModelIndex}
          setDetections={setDetections}
        />
      )}
      {(currStep === "viewFile" || currStep === "jobsHistoryViewFile") && (
        <ViewFile
          currStep={currStep}
          setCurrStep={setCurrStep}
          detections={detections}
          files={files}
          setFiles={setFiles}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
        />
      )}

      {currStep === "jobsHistoryList" && (
        <JobsHistoryList setCurrStep={setCurrStep} setJobID={setJobID} />
      )}
    </>
  );
};

export default Landing;
