import { List } from "antd";
import {
  Background,
  WhiteCard,
} from "../../StyleComponents/Background/CustomBackgrounds";
import { HyperLink } from "../../StyleComponents/Links/CustomLinks";
import CustomList from "../../StyleComponents/Lists/CustomList";
import {
  NormalText,
  SemiBoldText,
} from "../../StyleComponents/Texts/CustomTexts";
import { CustomButton } from "../../StyleComponents/Buttons/CustomButton";
import { useEffect, useState } from "react";

const JobsHistoryList = ({ setCurrStep, setJobID }) => {
  const currentUrl = window.location.href;
  const [allJobs, setAllJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        let response;
        if (currentUrl?.includes("localhost")) {
          // local host testing

          response = await fetch(`http://127.0.0.1:8000/jobs`);
        } else {
          // ec2

          response = await fetch(
            `https://mfuws72uzl.execute-api.us-east-1.amazonaws.com/jobs`
          );
        }

        if (!response.ok) throw new Error("Network response was not ok");

        const result = await response.json();

        if (result) {
          setAllJobs([...result]);
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData(); // Initial fetch
  }, []);

  const goToHomeOnJobsHistory = () => {
    setCurrStep("uploadFiles");
  };

  const epochToLocalTime = (epoch) => {
    const date = new Date(epoch * 1000);
    return date.toLocaleString();
  };

  return (
    <Background>
      <WhiteCard
        style={{
          minHeight: "82vh",
          paddingBottom: "80px", // Prevents overlap with fixed button
          position: "relative",
        }}
        className="row"
      >
        <CustomList
          className=""
          loading={loading}
          dataSource={allJobs}
          border
          header={
            <div className="d-flex flex-row align-items-center justify-content-center w-100">
              <div className="d-flex flex-column justify-content-center align-items-start col-3">
                <SemiBoldText>Job ID</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>Time Stamp</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>Total Files</SemiBoldText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-3">
                <SemiBoldText>View </SemiBoldText>
              </div>
            </div>
          }
          renderItem={(item, index) => (
            <List.Item
              style={{ padding: "15px" }}
              className=" d-flex flex-row w-100"
            >
              <div className="d-flex flex-row  align-items-center justify-content-center w-100">
                <div className="d-flex flex-column justify-content-center align-items-start col-3">
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <SemiBoldText>{item?.id}</SemiBoldText>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <NormalText>{epochToLocalTime(item?.created_at)}</NormalText>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <NormalText>{item?.no_files}</NormalText>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-3">
                  <HyperLink
                    onClick={() => {
                      setJobID(item?.id);
                      setCurrStep("jobsHistoryListFiles");
                    }}
                  >
                    View
                  </HyperLink>
                </div>
              </div>
            </List.Item>
          )}
        />

        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomButton
            width="250px"
            customtype="primary"
            onClick={() => goToHomeOnJobsHistory()}
          >
            Go to Home
          </CustomButton>
        </div>
      </WhiteCard>
    </Background>
  );
};

export default JobsHistoryList;
