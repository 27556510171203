import { createSlice } from "@reduxjs/toolkit";

const PermissionsSlice = createSlice({
  name: "permissions",
  initialState: {},
  reducers: {
    setPermissions: (state, action) => {
      Object.keys(action.payload).forEach((val) => {
        state[val] = action.payload[val];
      });
    },
  },
});

export const { setPermissions } = PermissionsSlice.actions;
export default PermissionsSlice.reducer;
