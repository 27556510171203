import AWS from "aws-sdk";
import {
  Background,
  WhiteCard,
} from "../../StyleComponents/Background/CustomBackgrounds";
import { List, Progress, Upload, notification } from "antd";
import {
  CustomText,
  NormalText,
  SemiBoldText,
} from "../../StyleComponents/Texts/CustomTexts";
import { colors } from "../../Styles/Colors";
import { fonts } from "../../Styles/Typography";
import { HyperLink } from "../../StyleComponents/Links/CustomLinks";
import upload from "../../Assets/Icons/Black/upload.png";
import trash from "../../Assets/Icons/Black/trash.png";
import { CustomButton } from "../../StyleComponents/Buttons/CustomButton";
import CustomList from "../../StyleComponents/Lists/CustomList";
import axios from "axios";
const { Dragger } = Upload;
const bucket = "prototypev1";
const MAX_FILE_SIZE = 50 * 1024 * 1024;
const MAX_FILE_COUNT = 5;

AWS.config.update({
  accessKeyId: "AKIA6G75DKP7TFNEQLWJ", // Replace with your access key
  secretAccessKey: "tWdfNfHCIn7Ix/YMk9p+1hD/YV7FQlUO56hM90jP", // Replace with your secret key
  region: "us-east-1", // Replace with your region
});

const s3 = new AWS.S3();

const UploadFiles = ({ jobID, setCurrStep, files, setFiles }) => {
  const currentUrl = window.location.href;

  const uploadtoS3 = async (file, onProgress) => {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: bucket, // Replace with your bucket name
        Key: `jobs/${jobID}/input/${file?.name}`,
        Body: file,
        ContentType: file.type,
      };

      s3.upload(params)
        .on("httpUploadProgress", (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          onProgress(file.name, progress); // Update the progress for the file
        })
        .send((err, data) => {
          if (err) {
            console.error("Error uploading the file...", err);
            notification.error({
              message: "Upload failed!",
              description: `Failed to upload ${file.name}. Please try again!`,
              duration: 3,
            });
            reject(err);
          } else {
            console.log("File uploaded successfully!");
            notification.success({
              message: "Upload Successful",
              description: `${file.name} has been uploaded successfully!`,
              duration: 3,
            });
            resolve(data.Location);
          }
        });
    });
  };

  const handleUpload = async (file) => {
    let allFiles = files;

    const isDuplicate = allFiles.some(
      (existingFile) => existingFile.file_name === file.name
    );

    if (isDuplicate) {
      notification.error({
        message: "Duplicate File",
        description: "You are trying to upload duplicate file!",
        duration: 3,
      });
      return;
    }

    let tempFile = {};
    tempFile.file_name = file.name;
    tempFile.size = file.size;
    tempFile.s3Key = `jobs/${jobID}/input/${file?.name}`;
    tempFile.progess = 0;
    tempFile.status = "uploading";

    allFiles.push(tempFile);

    // set the array for all files
    setFiles([...allFiles]);

    // s3 upload
    try {
      await uploadtoS3(file, (fileName, progress) => {
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.file_name === fileName ? { ...file, progress } : file
          )
        );
      });

      // Mark the file as done after upload
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.file_name === file.name
            ? { ...f, status: "done", progress: 100 }
            : f
        )
      );
    } catch (err) {
      notification.error({
        message: "Upload Failed",
        description: "Error while uploading",
        duration: 3,
      });
      console.log("Upload failed...");
    }
  };

  const deleteFilefroms3 = async (s3Key) => {
    const params = {
      Bucket: bucket, // Replace with your bucket name
      Key: s3Key, // The name of the file to delete
    };

    await s3.deleteObject(params, (err, data) => {
      if (err) {
        notification.error({
          message: "Delete File Error ",
          description: "Error while trying to delete the file!",
          duration: 3,
        });
        console.error("Error deleting file: ", err);
      } else {
        notification.success({
          message: "File Deleted!",
          description: "File successfully Deleted From S3!",
          duration: 3,
        });
        console.log("Successfully deleted file from S3:", data);
      }
      console.log("Successfully deleted file from S3:", data);
    });
  };

  const handleDelete = async (file, index) => {
    let allFiles = files;
    allFiles.splice(index, 1);

    // implemented s3 Delete as well
    await deleteFilefroms3(file?.s3Key);

    setFiles([...allFiles]);
  };

  const handleSubmit = async () => {
    // implementation

    let tempFileNameArr = [];

    for (let i = 0; i < files?.length; i++) {
      tempFileNameArr.push(files[i].file_name);
    }

    let params = {
      id: jobID,
      files: tempFileNameArr,
      models: [
        "yolov8m_best",
        // "yolov8s_0.01_best",
        // "yolov8s_0.1_best",
        // "yolov8s_0.25_best",
        // "yolov8s_1_best",
        // "yortm_0.1_best",
      ],
    };

    try {
      let response;
      if (currentUrl?.includes("localhost")) {
        // local host testing

        response = await axios.post(`http://127.0.0.1:8000/jobs`, params);
      } else {
        // ec2

        response = await axios.post(
          `https://mfuws72uzl.execute-api.us-east-1.amazonaws.com/jobs`,
          params
        );
      }

      if (response.status === 200) {
        // success
      }
    } catch (error) {
      console.log(error);
    }

    setCurrStep("listFiles");
  };

  return (
    <Background className="mt-0">
      <div>
        <WhiteCard className="row">
          <Dragger
            className="d-flex justify-content-center align-items-center mt-3"
            fileList={[]}
            limit={5}
            maxCount={MAX_FILE_COUNT}
            multiple
            beforeUpload={(file) => {
              // File size validation
              if (file.size > MAX_FILE_SIZE) {
                //we can replace below error message with notification-antd
                notification.error({
                  message: "File Size Exceeded",
                  description: `File size exceeds the ${
                    MAX_FILE_SIZE / (1024 * 1024)
                  } MB limit.`,
                  duration: 3, // Display for 3 seconds
                });
                //console.error(`File size exceeds the ${MAX_FILE_SIZE / (1024 * 1024)} MB limit.`);
                return Upload.LIST_IGNORE; // Prevent upload
              }

              const reader = new FileReader();
              reader.onload = (e) => {};
              reader.readAsText(file);
              return false;
            }}
            onChange={(e) => {
              handleUpload(e.file);
            }}
            accept=".jpg,.jpeg,.png,.mp4, .mkv, .mov"
            style={{
              border: `3px dotted ${colors.astronautBlue}`,
              background: "transparent",
            }}
            disabled={files.length >= MAX_FILE_COUNT} // Disable Drag&Drop when file count reaches MAX_FILE_COUNT
          >
            <div className=" d-flex flex-column justify-content-center align-items-center align-items-center">
              <img style={{ width: "50px" }} alt="uploadimage" src={upload} />
              <CustomText
                fontFamily={fonts.secondary}
                fontWeight={"600"}
                color={colors.ink}
                fontSize={"20px"}
              >
                <HyperLink>CLICK</HyperLink> OR <HyperLink>DRAG</HyperLink>{" "}
                FILES TO THIS AREA TO UPLOAD
              </CustomText>
            </div>
          </Dragger>
        </WhiteCard>

        <WhiteCard
          style={{
            height: "450px",
            position: "relative",
            paddingBottom: "80px",
          }}
          className="row"
        >
          <div>
            <CustomList
              className=""
              // loading={alertsIsFetching}
              dataSource={files}
              // pagination={{
              // pageSize: alertsPageData?.size,
              // showSizeChanger: false,
              // total: totalSize,
              // onChange: (e) => handlePageChange(e),
              // current: alertsPageData?.number,
              // }}
              border
              header={
                <div className="d-flex flex-row align-items-center justify-content-center w-100">
                  <div className="d-flex flex-column justify-content-center align-items-start col-3">
                    <SemiBoldText>File Name</SemiBoldText>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center col-3">
                    <SemiBoldText>File Size</SemiBoldText>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center col-3">
                    <SemiBoldText>Progress</SemiBoldText>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center col-3">
                    <SemiBoldText>Delete </SemiBoldText>
                  </div>
                </div>
              }
              renderItem={(item, index) => (
                <List.Item
                  style={{ padding: "15px" }}
                  className=" d-flex flex-row w-100"
                >
                  <div className="d-flex flex-row  align-items-center justify-content-center w-100">
                    <div className="d-flex flex-column justify-content-center align-items-start col-3">
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <SemiBoldText>{item?.file_name}</SemiBoldText>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center col-3">
                      <NormalText>{item?.size}</NormalText>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center col-3">
                      <Progress
                        percent={item.progress}
                        status={item.progress === 100 ? "success" : "active"}
                        size="small"
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center col-3">
                      <img
                        style={{ width: "20px" }}
                        alt="trashImage"
                        src={trash}
                        onClick={() => {
                          handleDelete(item, index);
                        }}
                      />
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <CustomButton
              width="250px"
              customtype="primary"
              onClick={() => setCurrStep("jobsHistoryList")}
            >
              Jobs History
            </CustomButton>
            <CustomButton
              width="250px"
              customtype="primary"
              onClick={() => handleSubmit()}
              disabled={
                files.some((file) => file.status === "uploading") ||
                files.length === 0
              }
            >
              Submit
            </CustomButton>
          </div>
        </WhiteCard>
      </div>
    </Background>
  );
};

export default UploadFiles;
