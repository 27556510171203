import Header from "./Header";
import { useState } from "react";

const Layout = ({ CurrentComponent, componentName }) => {
  const [width, setWidth] = useState("100px");

  const newStyle = {
    // position: "fixed",
    // left: "100px",
    width: "calc(100%)",
    // overflowY: "auto",
    height: "100vh",
  };

  return (
    <div className="d-flex flex-row">
      <div style={newStyle}>
        <div className="d-flex flex-column w-100">
          {/* Header*/}
          {/* <Header componentName={componentName} /> */}
          {/* Divider the 25px margin bottom i*/}

          <div>{CurrentComponent && CurrentComponent}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
