import styled from "styled-components";
import { colors } from "../../Styles/Colors";
import { fontSize, fontWeight, fonts } from "../../Styles/Typography";
import { Button } from "antd";

/**
 * @typedef {object} CustomButton
 * @param {string} [customtype] -Optional param type can be used to set the type of the button default "primary".
 * @param {string} width - Param width can be used to set the width of the button default "100%".
 * @param {function} onClick - The function to be called when the button is clicked.
 * @returns {React.Element} A React element representing a custom button component.
 * @example<CustomButton type='secondary' onClick={()=>{console.log("hi")}}>EXAMPLE Text</CustomButton>
 */

export const CustomButton = styled(Button).attrs((props) => ({
  customtype: props.customtype,
  width: props.width,
}))`
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: ${(props) => (props.width ? props.width : "100%")};

  font-size: ${fontSize.subtext};
  font-weight: ${fontWeight.bold};
  gap: 10px;
  padding: 10px 30px;


  color: ${(props) =>
    props.customtype === "secondary" ? colors.ink : colors?.wildSand};

  background-color: ${(props) =>
    props.customtype === "secondary" ? colors?.wildSand : colors.ink};

  border: 1px solid ${colors.ink};

  &.ant-btn:active,
  &.ant-btn:hover,
  &.ant-btn:focus {
    text-decoration: none;
    color: ${(props) =>
      props.customtype === "secondary" ? colors.ink : colors?.wildSand};

    background: ${(props) =>
      props.customtype === "secondary" ? colors?.wildSand : colors.ink};

    border: 1px solid ${colors.ink};
      
  }
  span {
    font-family: ${fonts.secondary}; !important;
  }
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: ${(props) =>
      props.customtype === "secondary" ? colors.ink : colors?.wildSand};
    border-color: ${colors.ink};
}

&.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: ${(props) =>
    props.customtype === "secondary" ? colors.ink : colors.white};
    border-color: ${colors.ink};
    background:  ${(props) =>
      props.customtype === "secondary" ? colors?.wildSand : colors.ink};
}


  &.ant-btn[disabled],
  &.ant-btn-primary:disabled,
  &.ant-btn:active:disabled,
  &.ant-btn:hover:disabled,
  &.ant-btn:focus:disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background-color: #f0f0f0;
  }
`;

// export const CustomButton = styled.button`
//   background: ${(props) =>
//     props.customtype !== "secondary" ? colors.ink : colors?.wildSand};
//   color: ${(props) =>
//     props.customtype !== "secondary" ? colors?.wildSand : colors.ink};
//   border: ${(props) =>
//     props.customtype !== "secondary"
//       ? `none`
//       : ` 1px solid ${colors.ink}`};

//   width: ${(props) => (props.width ? props.width : "100%")};
//   font-weight: ${fontWeight.bold};
//   font-size: ${fontSize.subtext};
// font-family: ${fonts.secondary};
//   border-radius: 20px;
//   height: 40px;
//   padding: 10px 30px;
//   gap: 10px;
// `;
