import styled from "styled-components";
import { colors } from "../../Styles/Colors";
import { fontSize, fontWeight, fonts } from "../../Styles/Typography";
import { Popover } from "antd";

const checkOverFlow = (children, overflowLength) => {
  if (overflowLength) {
    if (children?.length > overflowLength) {
      return (
        <div
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "block",
            overflow: "hidden",
          }}
        >
          <Popover
            placement="topLeft"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  overflowY: "auto",
                  wordBreak: "break-word",
                }}
              >
                <NormalText className="col-10">{children}</NormalText>
              </div>
            }
          >
            {children}
          </Popover>
        </div>
      );
    } else {
      return children;
    }
  } else {
    return children;
  }
};

const CustomHeaderText = styled.span`
  color: ${(props) => (props.color ? props.color : colors.ink)};
  font-family: ${fonts.secondary};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.header};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
`;
/**
 * @module HeaderText
 * @typedef {object} HeaderText
 * @property {string} [color] - The color of the subText text (optional).
 * @property {integer} [overflowLength] - If this text might overflow add "overflowLength" to check
 * if the text excedes the number entered, if so it will turn the text into an elipsis
 * and then add a popover that the user can hover over to see the full text.
 * @returns {React.Element} A React element representing a header text component.
 * @description This component renders a piece of header text with specified styling.
 * It can be used for displaying body/regular text content on a page.
 * The `HeaderText` component accepts text to be displayed.
 * @example
 * // Use the HeaderText in your component
 *   <HeaderText color={colors.astronautBlue} overflowLength={30}>EXAMPLE TEXT</HeaderText>
 */
export const HeaderText = ({
  className,
  key,
  children,
  style,
  color,
  overflowLength,
  maxWidth,
}) => {
  return (
    <CustomHeaderText
      key={key}
      className={className}
      style={style}
      color={color}
      maxWidth={maxWidth}
    >
      {checkOverFlow(children, overflowLength, "HeaderText")}
    </CustomHeaderText>
  );
};

/**
 * @module TitleText
 * @typedef {object} TitleText
 * @property {string} [color] - The color of the subText text (optional).
 * @returns {React.Element} A React element representing a title text component.
 * @description This component renders a piece of title text with specified styling.
 * It can be used for displaying body/regular text content on a page.
 * The `TitleText` component accepts text to be displayed.
 * @example
 * // Use the TitleText in your component
 *   <TitleText color={colors.astronautBlue}>EXAMPLE TEXT</TitleText>
 */
export const TitleText = styled.span`
  color: ${(props) => (props.color ? props.color : colors.ink)};
  font-family: ${fonts.secondary};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.title};
`;

/**
 * @module BoldText
 * @typedef {object} BoldText
 * @property {string} text - The text to display as bold text.
 * @returns {React.Element} A React element representing a bold text component.
 * @property {string} [color] - The color of the bold text (optional).
 * @description This component renders a piece of bold text with specified styling.
 * It can be used for displaying body/regular text content on a page.
 * The `BoldText` component accepts text to be displayed.
 * @example
 * // Import the BoldText component
 * import BoldText from './BoldText';
 *
 * // Use the BoldText in your component
 * function MyComponent() {
 *   return (
 *       <BoldText >This is a piece of bold text. </BoldText>
 *   );
 * }
 *
 * export default MyComponent;
 */
export const BoldText = styled.span`
  color: ${(props) => (props.color ? props.color : colors.gadget)};
  font-family: ${fonts.primary};
  font-size: ${fontSize.body};
  font-weight: ${fontWeight.bold};
`;

const CustomNormalText = styled.span`
  color: ${(props) =>
    props.color
      ? props.color
      : props.disabled
      ? colors.silverGrey
      : colors.gadget};
  cursor: ${(props) => props.disabled && "not-allowed"};
  font-family: ${fonts.primary};
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.body};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
`;
/**
 * @module NormalText
 * @typedef {object} NormalText
 * @property {string} [color] - The color of the subText text (optional).
 * @property {integer} [overflowLength] - If this text might overflow add "overflowLength" to check
 * if the text excedes the number entered, if so it will turn the text into an elipsis
 * and then add a popover that the user can hover over to see the full text.
 * @returns {React.Element} A React element representing a normal text component.
 * @description This component renders a piece of normal text with specified styling.
 * It can be used for displaying body/regular text content on a page.
 * The `NormalText` component accepts text to be displayed.
 * @example
 * // Import the NormalText component
 * import NormalText from './NormalText';
 *
 * // Use the NormalText in your component
 * function MyComponent() {
 *   return (
 *       <NormalText color={colors.astronautBlue} overflowLength={30} >This is a piece of normal text. </NormalText>
 *   );
 * }
 *
 * export default MyComponent;
 */
export const NormalText = ({
  className,
  key,
  children,
  style,
  color,
  overflowLength,
  maxWidth,
}) => {
  return (
    <CustomNormalText
      key={key}
      className={className}
      style={style}
      color={color}
      maxWidth={maxWidth}
    >
      {checkOverFlow(children, overflowLength)}
    </CustomNormalText>
  );
};

/**
 * @module SubText
 * @typedef {object} SubText
 * @property {string} text - The text to display as subText text.
 * @returns {React.Element} A React element representing a subText text component.
 * @property {string} [color] - The color of the subText text (optional).
 * @description This component renders a piece of subText text with specified styling.
 * It can be used for displaying body/regular text content on a page.
 * The `SubText` component accepts text to be displayed.
 * @example
 * // Import the SubText component
 * import SubText from './SubText';
 *
 * // Use the SubText in your component
 * function MyComponent() {
 *   return (
 *       <SubText >This is a piece of subText . </SubText>
 *   );
 * }
 *
 * export default MyComponent;
 */
export const SubText = styled.span`
  color: ${(props) => (props.color ? props.color : colors.gadget)};
  font-family: ${fonts.primary};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.bold};
  font-size: ${fontSize.subtext};
`;

const CustomSemiBoldText = styled.span`
  color: ${(props) =>
    props.color
      ? props.color
      : props.disabled
      ? colors.silverGrey
      : colors.gadget};
  cursor: ${(props) => props.disabled && "not-allowed"};
  font-family: ${fonts.primary};
  font-size: ${fontSize.body};
  font-weight: ${fontWeight.mediumBold};
`;
/**
 * @module SemiBoldText
 * @typedef {object} SemiBoldText
 * @property {string} text - The text to display as header text.
 * @property {integer} [overflowLength] - If this text might overflow add "overflowLength" to check
 * if the text excedes the number entered, if so it will turn the text into an elipsis
 * and then add a popover that the user can hover over to see the full text.
 * @returns {React.Element} A React element representing a header text component.
 * @property {string} [color] - The color of the subText text (optional).
 * @description This component renders a piece of header text with specified styling.
 * It can be used for displaying body/regular text content on a page.
 * The `SemiBoldText` component accepts text to be displayed.
 * @example
 * // Import the SemiBoldText component
 * import SemiBoldText from './SemiBoldText';
 *
 * // Use the SemiBoldText in your component
 * function MyComponent() {
 *   return (
 *       <SemiBoldText color={colors.astronautBlue} overflowLength={30}>This is a piece of SemiBoldText . </SemiBoldText>
 *   );
 * }
 *
 * export default MyComponent;
 */
export const SemiBoldText = ({
  className,
  key,
  children,
  style,
  color,
  overflowLength,
  maxWidth,
}) => {
  return (
    <CustomSemiBoldText
      key={key}
      className={className}
      style={style}
      color={color}
      maxWidth={maxWidth}
    >
      {checkOverFlow(children, overflowLength)}
    </CustomSemiBoldText>
  );
};

const StyledCustomText = styled.span`
  color: ${(props) => (props.color ? props.color : colors.gadget)};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : fonts.primary};
  font-size: ${(props) => (props.fontSize ? props.fontSize : fontSize.body)};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fontWeight.regular};
  font-style: ${(props) => (props.fontStyle ? props.fontStyle : "normal")};
`;
/**
 * @module CustomText
 * @typedef {object} CustomText
 * @property {string} text - The text to display as header text.
 * @property {integer} [overflowLength] - If this text might overflow add "overflowLength" to check
 * if the text excedes the number entered, if so it will turn the text into an elipsis
 * and then add a popover that the user can hover over to see the full text.
 * @returns {React.Element} A React element representing a header text component.
 * @property {string} [color] - The color of the subText text (optional).
 * @description This component renders a piece of header text with specified styling.
 * It can be used for displaying body/regular text content on a page.
 * The `CustomText` component accepts text to be displayed.
 * @example
 * // Import the CustomText component
 * import CustomText from './CustomText';
 *
 * // Use the CustomText in your component
 * function MyComponent() {
 *   return (
 *       <CustomText color={colors.astronautBlue} overflowLength={30}>This is a piece of CustomText . </CustomText>
 *   );
 * }
 *
 * export default MyComponent;
 */
export const CustomText = ({
  className,
  key,
  children,
  style,
  color,
  overflowLength,
  maxWidth,
  fontFamily,
  fontStyle,
  fontWeight,
  fontSize,
}) => {
  return (
    <StyledCustomText
      key={key}
      className={className}
      style={style}
      color={color}
      maxWidth={maxWidth}
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      fontSize={fontSize}
    >
      {checkOverFlow(children, overflowLength)}
    </StyledCustomText>
  );
};
