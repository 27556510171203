// rootReducer.js
import { combineReducers } from "redux";
import AuthSlice from "./AuthSlice";
import UserSlice from "./UserSlice";
import PermissionsSlice from "./PermissionsSlice";
import { apiSlice } from "./RealTimeSlice";

const rootReducer = combineReducers({
  auth: AuthSlice,
  user: UserSlice,
  permissions: PermissionsSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default rootReducer;
