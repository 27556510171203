import Landing from "./Pages/DemoFlow/Landing";

export const routeData = [
  {
    title: "LANDING",
    path: "*",
    component: <Landing />,
  },
];

export const publicRouteData = [];
