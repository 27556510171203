import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
  name: "auth",
  initialState: null,
  reducers: {
    updateAuth: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateAuth } = AuthSlice.actions;
export default AuthSlice.reducer;
