import { Routes, Route, useLocation } from "react-router-dom";
import { routeData } from "../RouteData";
import Layout from "../GlobalComponents/Layout";
import ComingSoon from "./ComingSoon/ComingSoonLanding";

const Main = () => {
  const location = useLocation();

  const currentRoute = routeData.find(
    (route) => route.path === location.pathname
  );

  const routeComponents = routeData.map((route) => (
    <Route key={route.path} path={route.path} element={route.component} />
  ));

  return (
    <Routes>
      <Route
        element={
          // authenticatedProps ? (
          //   <PrivateRoutes
          //     componentName={currentRoute ? currentRoute.title : "DASHBOARD"}
          //   />
          // ) : (
          //   <PublicRoutes />
          // )
          <Layout componentName={"LANDING"} CurrentComponent={<ComingSoon />} />
        }
      >
        {routeComponents}
      </Route>
    </Routes>
  );
};
export default Main;
