import { useState } from "react";
import ComingSoonHome from "./ComingSoonHome";

const Landing = () => {
  const [currStep, setCurrStep] = useState("home");

  return <>{currStep === "home" && <ComingSoonHome />}</>;
};

export default Landing;
